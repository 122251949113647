import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiConstant } from "../../api/apiEndPoints";
import { getRequestApi, postRequestApi } from "../../api/axiosClient";

export const getServices = createAsyncThunk('HOME/getServices', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getServices,
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addInquiry = createAsyncThunk('HOME/addInquiry', async (data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.addInquiry(data.name, data.email, data.phone, data.service, data.message),
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getfaqs = createAsyncThunk('HOME/getfaqs', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getfaqs,
    ))
    if (response?.data) {
      console.log("response?.data===", response?.data);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBlogList = createAsyncThunk('HOME/getBlogList', async (_, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.getBlogList,
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBlogDetails = createAsyncThunk('HOME/getBlogDetails', async (data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.getBlogDetails(data.id),
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getServiceDetailsById = createAsyncThunk('HOME/getServiceDetailsById', async (data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.getServiceDetailsById(data.id),
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const topBanners = createAsyncThunk('HOME/topBanners', async (data, { rejectWithValue }) => {
  try {
    const response = (await getRequestApi(
      ApiConstant.topBanners(data?.type),
    ))
    if (response?.data) {
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const newsLetters = createAsyncThunk('AUTH/newsLetters', async (Data, { rejectWithValue }) => {
  try {
    const response = (await postRequestApi(
      ApiConstant.newsLetters(Data.email),
    ))
    if (response) {
      console.log("response?.data===", response);
      return response;
    } else {
      return rejectWithValue(response);
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});