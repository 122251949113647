import { createSlice } from "@reduxjs/toolkit";
import {
  addInquiry,
  getBlogDetails,
  getBlogList,
  getfaqs,
  getServiceDetailsById,
  getServices,
  newsLetters,
  topBanners,
} from "./action";

const initialState = {
  isLoading: false,
  error: undefined,
  servicesList: null,
  addInquiryData: null,
  faqsList: [],
  BlogList: null,
  blogDetails: null,
  serviceDetails: null,
  topBannerList: null,
  newsLettersData: null,
};

const homeSlice = createSlice({
  name: "HOME",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServices.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.servicesList = action.payload;
    });
    builder.addCase(getServices.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addInquiry.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(addInquiry.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addInquiryData = action.payload;
    });
    builder.addCase(addInquiry.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getfaqs.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getfaqs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.faqsList = action.payload;
    });
    builder.addCase(getfaqs.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getBlogList.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getBlogList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.BlogList = action.payload;
    });
    builder.addCase(getBlogList.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getBlogDetails.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getBlogDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.blogDetails = action.payload;
    });
    builder.addCase(getBlogDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getServiceDetailsById.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(getServiceDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.serviceDetails = action.payload;
    });
    builder.addCase(getServiceDetailsById.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(topBanners.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(topBanners.fulfilled, (state, action) => {
      state.isLoading = false;
      state.topBannerList = action.payload;
    });
    builder.addCase(topBanners.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(newsLetters.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(newsLetters.fulfilled, (state, action) => {
      state.isLoading = false;
      state.newsLettersData = action.payload;
    });
    builder.addCase(newsLetters.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default homeSlice.reducer;
