import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../store/Auth/reducer";
import homeReducer from "../store/Home/reducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
  },
});
