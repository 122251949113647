import React from "react";

const Home = React.lazy(() => import('../screens/Home'));
const FAQs = React.lazy(() => import('../screens/FAQs'));
const Signup = React.lazy(() => import('../screens/Signup'));
const Login = React.lazy(() => import('../screens/Login'));
const ResetPassword = React.lazy(() => import('../screens/ResetPassword'));
const Subscription = React.lazy(() => import('../screens/Subscription'));
const ForgotPassword = React.lazy(() => import('../screens/ForgotPassword'));
const Dashboard = React.lazy(() => import('../screens/Dashboard'));
const BlogList = React.lazy(() => import('../screens/BlogList'));
const BlogDetails = React.lazy(() => import('../screens/BlogDetails'));
const PetAdoption = React.lazy(() => import('../screens/PetAdoption'));

export const PublicRoutesArr = [
    { path: "/signup", exact: true, name: "Signup", element: Signup },
    { path: "/login", exact: true, name: "Login", element: Login },
];

export const PrivateRoutesArr = [
    { path: "/dashboard", exact: true, name: "Dashboard", element: Dashboard },
];

export const RoutesList = [
    { path: "/", exact: true, name: "Home", element: Home },
    { path: "/FAQs", exact: true, name: "FAQs", element: FAQs },
    { path: "/resetPassword/:email", exact: true, name: "ResetPassword", element: ResetPassword },
    { path: "/forgotpassword", exact: true, name: "ForgotPassword", element: ForgotPassword },
    { path: "/subscription", exact: true, name: "Subscription", element: Subscription },
    { path: "/bloglist", exact: true, name: "BlogList", element: BlogList },
    { path: "/blogdetails/:id", exact: true, name: "BlogDetails", element: BlogDetails },
    { path: "/petadoption", exact: true, name: "PetAdoption", element: PetAdoption },
]