import { createSlice } from "@reduxjs/toolkit";
import { signIn, signup, resetPassword, forgetPassword } from "./action";

const initialState = {
  isLoading: false,
  error: undefined,
  userInfo: null,
  userList: [],
  forgotData: null,
  resetData: null,
};

const authSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(signup.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.resetData = action.payload;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(forgetPassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.forgotData = action.payload;
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default authSlice.reducer;
