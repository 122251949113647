import React from "react";
import { imagesAss } from "../../assest/images";
import "./index.scss";

const Loader = () => {
  return (
    <div className="loader">
      <img src={imagesAss.logoTransparent} alt="logo" className="loader-icon" />
    </div>
  );
};

export default Loader;
