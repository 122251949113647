import { useDispatch } from "react-redux";
import "./App.css";
import BrowserRouter from "./routers";
import { getServices } from "./store/Home/action";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  useEffect(() => {
    // get services action
    dispatch(getServices())
      .unwrap()
      .then((res) => {
        // console.log("services list res=====", res);
      })
      .catch((err) => {
        console.log("err====???/", err);
      });
  }, [dispatch]);

  return (
    <>
      <BrowserRouter />
      <ToastContainer />
    </>
  );
};

export default App;
