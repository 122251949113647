import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PrivateRoutesArr, PublicRoutesArr, RoutesList } from "./RouteList";
import ScrollToTop from "../components/ScrollToTop";
import PublicRoutes from "./public";
import PrivateRoutes from "./private";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

const Telehealth = React.lazy(() => import("../screens/Telehealth"));
const PetDiscountedRX = React.lazy(() => import("../screens/PetDiscountedRX"));
const VetDiscount = React.lazy(() => import("../screens/VetDiscount"));
const LostPetNotification = React.lazy(() => import("../screens/LostPetNotification"));
const PetAdoption = React.lazy(() => import("../screens/PetAdoption"));
const Home = React.lazy(() => import("../screens/Home"));

const BrowserRouter = () => {
  const loading = () => <Loader />;
  const { servicesList } = useSelector((state) => state.home);

  return (
    <Router>
      <Suspense fallback={loading()}>
        <ScrollToTop />
        <Routes>
          {RoutesList.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={<route.element />}
              />
            );
          })}
          {/* For dynamic service route  */}
          {servicesList?.data?.map((route, index) => {
            const renderElement = () => {
              switch (route?.layout) {
                case "telehealth":
                  return <Telehealth />;
                case "pet_retail_discounts":
                  return <PetDiscountedRX />;
                case "vet_discount_plan":
                  return <VetDiscount />;
                case "lost_pet_notification_services":
                  return <LostPetNotification />;
                case "pharmacy":
                  return <Home />;
                case "pet_health_cloud":
                  return <PetAdoption />;
                default:
                  return null;
              }
            };
            return (
              <Route key={index} path={route?.slug} element={renderElement()} />
            );
          })}
          {PublicRoutesArr.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PublicRoutes>
                    <route.element />
                  </PublicRoutes>
                }
              />
            );
          })}
          {PrivateRoutesArr.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoutes>
                    <route.element />
                  </PrivateRoutes>
                }
              />
            );
          })}
          {/* <Route path='*' element={<p>There's nothing here: 404!</p>} /> */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default BrowserRouter;
