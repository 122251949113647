const ApiConstant = {
  signUp: (name, email, password, confirm_password) =>
    `/register?name=${name}&email=${email}&phone=9900887766&password=${password}&confirm_password=${confirm_password}`,
  login: (username, password) =>
    `/login?username=${username}&password=${password}`,
  resetPassword: (email, password, confirm_password) =>
    `/reset-password?email=${email}&password=${password}&confirm_password=${confirm_password}`,
  getServices: "/services",
  addInquiry: (name, email, phone, service, message) =>
    `/add-inquiry?name=${name}&email=${email}&phone=${phone}&service=${service}&message=${message}`,
  getfaqs: "/faqs",
  getBlogList: "/blog-list",
  getServiceDetailsById: (id) => `/service-detail?service_id=${id}`,
  myProfile: (id) => `/my-profile?user_id=${id}`,
  forgetPassword: (email) => `/forget-password?email=${email}`,
  updateProfile: () => `/update-profile`,
  changePassword: (id, password, confirm_password, old_password) =>
    `/change-password?id=${id}&password=${password}&confirm_password=${confirm_password}&old_password=${old_password}`,
  getBlogDetails: (id) => `/blog-detail?blog_id=${id}`,
  cmsPages: "/cms-list",
  cmsDetailsById: (id) => `/cms-detail?cms_id=${id}`,
  topBanners: (type) => `/top-banners?type=${type}`,
  newsLetters: (email) => `/news-letters?email=${email}`,
};

export { ApiConstant };
